/* Apply base styles and utilities from Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define base styles for your application */
@layer base {
  html {
    font-family: "Inter", sans-serif;
    line-height: 1.3;
  }

  table {
    @apply min-w-full border border-gray-300;
  }

  th,
  td {
    @apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
  }

  thead {
    @apply bg-neutral-100;
  }

  th {
    @apply font-semibold;
  }

  a {
    @apply text-sky-500 hover:underline;
  }
}

/* Define light theme variables */
html.light {
  --bg-color: #ffffff;
  --text-color: #000000;
  /* Add other light theme variables as needed */
}

/* Define dark theme variables */
html.dark {
  --bg-color: #1a202c;
  --text-color: #ffffff;
  /* Add other dark theme variables as needed */
}

/* Apply theme-specific colors to body */
body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

/* Apply dark theme specific styles */
.dark-theme {
  background-color: #121212;
  color: white;
}

.dark-theme .table th,
.dark-theme .table td {
  background-color: #121212;
  color: white;
}

.dark-theme .table tbody tr:hover {
  background-color: #0a0a0a;
}
